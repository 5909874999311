import { motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "next-i18next";

import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  chakra,
  Divider,
  Flex,
  HStack,
  Square,
  StackProps,
  Text,
  TextProps,
  useMediaQuery,
  useQuery,
  VStack,
} from "@chakra-ui/react";

import { ChakraNextLink } from "../chakra-links";

export const LoadingIcon = () => {
  return (
    <EffectWrap>
      <Square
        size="2rem"
        bg="gray.200"
        opacity={0.56}
        rounded="full"
        pos="absolute"
        top={-5}
        p={-1}
      ></Square>
      <Square
        size="1.7em"
        bg="gray.400"
        rounded="full"
        top={"-17px"}
        pos="absolute"
      >
        {/* <ThreeDotsLoading /> */}
        <chakra.div bg="gray.100" className="spinner-5"></chakra.div>
      </Square>
    </EffectWrap>
  );
};

export const CheckStatusIcon = () => {
  return (
    // <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <EffectWrap>
      <Square
        size="2em"
        bg="salmon"
        opacity={0.56}
        rounded="full"
        pos="absolute"
        top={-5}
        p={-1}
      ></Square>
      <Square
        size="1.7em"
        bg="coral"
        rounded="full"
        top={"-17px"}
        pos="absolute"
      >
        <CheckIcon color="white" />
      </Square>
    </EffectWrap>
    // </motion.div>
  );
};

export const WrapCircle = ({ children }: { children: ReactNode }) => {
  return (
    <EffectWrap>
      <Square
        size="3em"
        bg="gray.200"
        opacity={0.56}
        rounded="full"
        pos="absolute"
        top={-8}
        p={-1}
      ></Square>
      <Square
        size="2.3em"
        bg="gray.400"
        rounded="full"
        top={"-25px"}
        pos="absolute"
      >
        {children}
      </Square>
    </EffectWrap>
  );
};

export const ReceiptItemDescription = (props: {
  title: string | ReactNode;
  value: string | ReactNode;
  stackProps?: StackProps;
}) => {
  const { title, value, stackProps } = props;
  const leftTextColor = "gray.600";
  const rightTextColor = "gray.600";
  const titleIsReactNode = React.isValidElement(title);
  const valueIsReactNode = React.isValidElement(value);
  return (
    <HStack
      id="receipt_item_description"
      w="full"
      justifyContent="space-between"
      px={2}
      fontSize="md"
      {...stackProps}
    >
      {!titleIsReactNode ? <Text color={leftTextColor}>{title}</Text> : title}
      {!valueIsReactNode ? (
        <Text
          fontWeight="bold"
          color={rightTextColor}
          data-cy={title}
          textOverflow="ellipsis"
          whiteSpace="pre-line"
          overflow="hidden"
          textAlign="end"
        >
          {value}
        </Text>
      ) : (
        value
      )}
    </HStack>
  );
};

export const ReceiptSection = (props: {
  children: ReactNode;
  stackProps?: StackProps;
}) => {
  const { children } = props;

  return (
    <EffectWrap>
      <VStack
        w="full"
        fontWeight="light"
        pt={4}
        transition="ease-in"
        align={"center"}
        {...props.stackProps}
      >
        {children}
      </VStack>
    </EffectWrap>
  );
};

export const StripeString = (props: { value: string }) => {
  const { value } = props;
  const [showFull, setShowFull] = useState(false);
  const verySmallQuery = useQuery({ below: "xs" });
  const [isVerySmall] = useMediaQuery(verySmallQuery);
  const rightTextColor = "gray.600";
  const ellipsisHandler = () => {
    setShowFull(true);
  };

  const shrinkTextHandler = () => {
    if (showFull) {
      setShowFull(false);
    }
  };
  return (
    <chakra.span
      onClick={shrinkTextHandler}
      fontWeight="bold"
      color={rightTextColor}
    >
      {!showFull ? value.slice(0, isVerySmall ? 8 : 15) : value}{" "}
      {!showFull && (
        <chakra.span onClick={ellipsisHandler} cursor="pointer">
          ...
        </chakra.span>
      )}
    </chakra.span>
  );
};

export const CallToAction = (props: { stackProps?: StackProps }) => {
  const { t } = useTranslation("checkout");
  return (
    <VStack
      align="center"
      py={4}
      w="full"
      justify={"center"}
      {...props.stackProps}
      color="gray.600"
    >
      <ChakraNextLink colorScheme={"brandOrange2"} href="/">
        ← {t("result_continue_shopping", { defaultValue: "Continue shopping" })}
      </ChakraNextLink>
      <Divider bg="gray.300" h="1.2px" />
      <ChakraNextLink
        href="/my-account/orders"
        _before={{ content: '"← "', visibility: "hidden" }}
      >
        {t("result_see_your_orders", { defaultValue: "See your orders" })} →
      </ChakraNextLink>
    </VStack>
  );
};

export const ProcessingSection = (props: { name: string }) => {
  const { name = "" } = props;
  const { t } = useTranslation("checkout");
  return (
    <EffectWrap>
      <VStack w="full">
        <Flex flexGrow={0.3}></Flex>
        <Text textAlign="start" w="full" color="gray.800">
          {t("result_processing", { defaultValue: "Processing" })}
        </Text>
        <Text textAlign="start" w="full" fontSize="lg" color="tomato">
          {name && `${name},`}
          <chakra.span color="gray.800">
            {t("result_verifying", {
              defaultValue: "we are verifying your payment, please wait...",
            })}
          </chakra.span>
        </Text>
      </VStack>
    </EffectWrap>
  );
};

export const MotionBox = motion<BoxProps>(Box);
export const MotionText = motion<TextProps>(Text);

const EffectWrap = (props: { children: ReactNode }) => {
  return (
    <MotionBox
      display="flex"
      justifyContent="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      w="full"
      exit={{ opacity: 0 }}
      //@ts-ignore
      transition={{ ease: "easeOut", duration: 0.9 }}
    >
      {props.children}
    </MotionBox>
  );
};
