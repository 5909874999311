import Image from "next/image";
import { useEffect, useState } from "react";

import { Page_Slide } from "@/graphql/__generated__/client-side";
import { logger } from "@/utils/helpers";
import { Box, Flex, Grid, GridItem, HStack, Text, TextProps, useMediaQuery, useQuery } from "@chakra-ui/react";
import { useGesture } from "@use-gesture/react";

import { ChakraNextLink } from "./chakra-links";

export const CarouselPromoDisplay = (props: { slides: Partial<Page_Slide>[]; section?: string }) => {
	const { slides } = props;

	const [mounted, setMounted] = useState(false);
	const smallQuery = useQuery({ below: "md" });
	const [isSmall] = useMediaQuery(smallQuery);
	const [rows, setRows] = useState(1);
	const [columns, setColumns] = useState(1);
	const arrowStyles: TextProps = {
		cursor: "pointer",
		pos: "absolute",
		top: "50%",
		w: "auto",
		mt: "-22px",
		p: "16px",
		color: "white",
		fontWeight: "bold",
		fontSize: "18px",
		transition: "0.6s ease",
		borderRadius: "0 3px 3px 0",
		userSelect: "none",
		_hover: {
			opacity: 0.8,
			bg: "black",
		},
	};

	const [currentSlide, setCurrentSlide] = useState(0);

	const slidesCount = slides?.length;

	const prevSlide = () => {
		setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
	};
	const nextSlide = () => {
		setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
	};
	const setSlide = (slide: number) => {
		setCurrentSlide(slide);
	};
	const carouselStyle = {
		transition: "all .5s",
		ml: `-${currentSlide * 100}%`,
	};

	// type TGestureBind = (...args: any[]) => ReactDOMAttributes;
	let timer: NodeJS.Timeout;
	const gestureBind = useGesture({
		onDragEnd: ({ direction: [dir_x], distance: [dist_x] }) => {
			logger.log("gestureBind ~ dir_x", dir_x);
			// logger.log("gestureBind ~ dir_x", dir_x)
			logger.log("gestureBind ~ dist_x", dist_x);
			if (dir_x > 0 && dist_x > 5 && isSmall) {
				prevSlide();
				clearTimeout(timer);
			}
			if (dir_x < 0 && dist_x > 5 && isSmall) {
				nextSlide();
				clearTimeout(timer);
			}
		},
	});

	useEffect(() => {
		const images_arr = slides?.[currentSlide]?.click_url_arr;
		if (images_arr?.length > 0 && images_arr[0].length > 0) {
			setRows(images_arr?.length);
			setColumns(images_arr[0].length);
		}
	}, []);

	useEffect(() => {
		timer = setTimeout(() => nextSlide(), (slides?.[currentSlide]?.duration ?? 5) * 1000);
		return () => clearTimeout(timer);
	}, [currentSlide]);

	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<Flex
			w={mounted ? "full" : "800px"}
			alignItems="center"
			justifyContent="center"
			maxW="1280px"
			id="carousel"
			h={mounted ? "full" : "410px"}
			overflowX="hidden"

			// maxW="1024px"
		>
			<Flex w="full" pos="relative" h="full">
				<Flex {...carouselStyle} w="full" h={mounted ? "full" : "410px"}>
					{slides?.map((slide, sid) => (
						<Grid
							templateRows={`repeat(${rows}, 1fr)`}
							templateColumns={`repeat(${columns}, 1fr)`}
							key={`${slide.id}`}
							gap={1}
							w="full"
							boxSize="full"
							shadow="md"
							flex="none"
						>
							<GridItem
								rowStart={1}
								colStart={1}
								colSpan={rows}
								rowSpan={columns}
								justifyContent="center"
								alignContent="center"
								justifyItems="center"
								alignItems="center"
								display="flex"
								pos={"relative"}
							>
								<Image
									src={slide?.image_url ?? ""}
									priority={true}
									style={{ objectFit: "contain" }}
									fill={true}
									sizes="(max-width: 300px) 438px, (max-width: 768px) 1024px"
									className="next-image_carousel_section"
									alt={`Image of ${slide?.alt_text}}`}
									// @ts-expect-error
									{...(gestureBind as unknown as TGestureBind)()}
								/>
							</GridItem>
							{slide?.click_url_arr?.map((row: string[], i: number) =>
								row.map((col, j) =>
									col ? (
										<GridItem key={j} rowStart={i + 1} colStart={j + 1} bg="tomato" opacity={0}>
											<ChakraNextLink
												w="full"
												h="full"
												as="div"
												href={col ?? "/"}
												// @ts-expect-error
												{...(gestureBind as unknown as TGestureBind)()}
												target="_blank"
											></ChakraNextLink>
										</GridItem>
									) : (
										<Flex key={j} w="full" h="full"></Flex>
									)
								)
							)}
						</Grid>
					))}
				</Flex>

				<Text {...arrowStyles} left="0" onClick={prevSlide} display={isSmall ? "none" : "unset"}>
					&#10094;
				</Text>
				<Text {...arrowStyles} right="0" onClick={nextSlide} display={isSmall ? "none" : "unset"}>
					&#10095;
				</Text>
				<HStack justify="center" pos="absolute" bottom="8px" w="full">
					{Array.from({ length: slidesCount }).map((_, slide) => (
						<Box
							key={`dots-${slide}`}
							cursor="pointer"
							boxSize={{ base: "7px", md: "15px" }}
							m="0 2px"
							bg={currentSlide === slide ? "blackAlpha.400" : "blackAlpha.200"}
							rounded="50%"
							display="inline-block"
							transition="background-color 0.6s ease"
							_hover={{ bg: "blackAlpha.800" }}
							onClick={() => setSlide(slide)}
						></Box>
					))}
				</HStack>
			</Flex>
		</Flex>
	);
};
