import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeoProps } from "next-seo";
import Image from "next/image";
import { useRouter } from "next/router";
import process from "process";
import { ReactNode, useEffect, useState } from "react";
import { FaSquare } from "react-icons/fa";

import { CarouselPromoDisplay } from "@/components/carousel-promo-display";
import { ChakraNextLink } from "@/components/chakra-links";
import { MotionBox } from "@/components/result/partials";
import { Lscq_GetProductsStoreQuery } from "@/graphql/__generated__/client-side";
import {
  getSdk,
  Lscq_GetCategoriesQuery,
  Lscq_GetSlidesQuery,
} from "@/graphql/__generated__/server-side";
import useAppStore from "@/store/use-app";
import { logger } from "@/utils/helpers";
import { minifyCss } from "@/utils/server-side-utils";
import { graphQLClient } from "@/utils/ssr-graphql-client";
import {
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { el } from "date-fns/locale";

type IndexProps = {
  data: Lscq_GetProductsStoreQuery["jb_products"];
  categories: Lscq_GetCategoriesQuery["categories"];
  services: Lscq_GetCategoriesQuery["service_classification"];
  pageSlides: Lscq_GetSlidesQuery["page_slide"];
};

function Page(props: IndexProps) {
  // logger.log("file: index.tsx:30 ~ Page ~ props:", props);
  const { data, categories, services, pageSlides } = props;
  const sid = useAppStore((s) => s.purchase.sid) ?? "";
  const { t } = useTranslation("common");
  const [baseUrl, setBaseUrl] = useState(`/web/category`);
  useEffect(() => {
    setBaseUrl(`/${!sid ? "web" : sid}/category`);
  }, [sid]);

  return (
    <>
      <VStack
        spacing={2}
        p={{ base: 2, md: 3 }}
        bg="white"
        w="full"
        h="full"
        pos="relative"
        align="center"
        className="__main_page"
        id="main_element"
      >
        <CarouselPromoDisplay slides={pageSlides} />
        <Heading
          justifyContent="flex-start"
          as="h1"
          w="full"
          fontSize="xl"
          fontWeight="bold"
          color="darkBlueText"
        >
          {t("common_our_range", { defaultValue: "Our range" })}
        </Heading>
        <ShopsSection
          baseUrl={baseUrl}
          data={categories.map((cat) => ({ ...cat, value: `${cat.value}` }))}
          prefix="Shop"
          urlSuffix="/1"
          id="shop_section"
        />
        <Heading
          justifyContent="flex-start"
          as="h1"
          w="full"
          fontSize="xl"
          fontWeight="bold"
          color="darkBlueText"
        >
          {t("common_services", { defaultValue: "Services" })}
        </Heading>
        <ShopsSection
          baseUrl="/services"
          data={services}
          prefetch={false}
          id="services_section"
        />
      </VStack>
    </>
  );
}

export default Page;

function ShopsSection(props: {
  baseUrl: string;
  data:
    | Lscq_GetCategoriesQuery["categories"]
    | Lscq_GetCategoriesQuery["service_classification"];
  prefix?: string;
  urlSuffix?: string;
  prefetch?: boolean;
  id?: string;
}) {
  const { baseUrl, data, prefix = "", prefetch = true, urlSuffix = "" } = props;
  // const
  const { t } = useTranslation("common");

  return (
    <SimpleGrid
      gridGap={2}
      id={props?.id}
      w="full"
      minChildWidth={"64px"}
      gridTemplateColumns={{
        base: "repeat(3,minmax(0,1fr))",
        smm2: "repeat(4,minmax(0,1fr))",
        md: "repeat(auto-fill,minmax(128px,1fr))",
      }}
    >
      {data?.map((el, i) => {
        return (
          <IconSection
            key={el.label}
            title={t(`common_${el.label}` as "common_Groceries", {
              defaultValue: el.label,
            })}
            href={`${baseUrl}/${el.value?.toLowerCase()}${urlSuffix}`}
            color={el?.color ?? "white"}
            prefetch={prefetch}
            insideElement={
              <Image
                fill={true}
                style={{ objectFit: "contain", minWidth: "96px" }}
                sizes="(min-width: 300px) 132px"
                alt={`Icon image of ${el.label}`}
                className={"next-image_icon_section"}
                src={el.image ?? "/placeholder-image.png"}
              />
            }
          />
        );
      })}
    </SimpleGrid>
  );
}

const IconSection = (props: {
  insideElement?: string | ReactNode;
  color?: string;
  title: string;
  href?: string;
  flexProps?: FlexProps;
  prefix?: string;
  prefetch?: boolean;
}) => {
  const {
    title,
    insideElement,
    flexProps,
    prefix = "",
    color = "green",
    href = "",
    prefetch = true,
  } = props;
  const { t } = useTranslation("common");
  const router = useRouter();
  return (
    <ChakraNextLink key={title} href={href} prefetch={prefetch} withQuery>
      <MotionBox whileHover={{ scale: 1.03 }}>
        <Flex
          bg={color}
          sx={{ aspectRatio: "1" }}
          pos="relative"
          boxShadow="lg"
          rounded="lg"
          // m="auto"
          justify="center"
          align={"center"}
          {...flexProps}
        >
          {insideElement}
        </Flex>
        <Heading
          as="h3"
          mt={0.5}
          fontSize={"md"}
          textAlign={"center"}
          fontWeight="bold"
          color="gray.600"
          textTransform={"capitalize"}
        >
          {title}
        </Heading>
      </MotionBox>
    </ChakraNextLink>
  );
};

const More = (props: { [x: string]: any }) => {
  const { ...rest } = props;
  const size = {
    fontSize: { base: "24px", sm: "32px", md: "48px" },
    opacity: 0.67,
  };
  return (
    <VStack justify="center" align="center" w="full">
      <HStack>
        <Icon {...size} color="brandOrange" as={FaSquare} />
        <Icon {...size} color="brandOrange" as={FaSquare} />
      </HStack>
      <HStack>
        <Icon {...size} color="brandOrange" as={FaSquare} />
        <Icon {...size} color="brandOrange" as={FaSquare} />
      </HStack>
    </VStack>
  );
};

const domain =
  process.env.NODE_ENV === "production"
    ? process.env.STAGE === "production"
      ? "www.latinshop.com.au"
      : "commerce.stage.latinshop.com"
    : "localhost:3001";

export const getStaticProps: GetStaticProps = async (ctx) => {
  try {
    const categories = await getSdk(graphQLClient).LSCQ_GetCategories();
    const pageSlides = await getSdk(graphQLClient).LSCQ_GetSlides({ domain });
    const seo = (pageSlides?.data?.site_config?.[0]?.meta?.seo ?? {
      title: "LATINSHOP.COM.AU",
    }) as NextSeoProps;
    const localeData = await serverSideTranslations(ctx.locale!, [
      "common",
      "error",
    ]);

    const _css =
      (await minifyCss(pageSlides?.data?.site_config?.[0]?.meta?._css ?? "")) ??
      "";
    return {
      props: {
        categories: categories.data?.categories,
        services: categories?.data?.service_classification,
        pageSlides: pageSlides?.data?.page_slide,
        seo,
        _css,
        ...localeData,
      },
      revalidate: 60,
    };
  } catch (err) {
    logger.log("indexPage", err);
    return {
      revalidate: 15,
      notFound: true,
    };
  }
};
